<template>
    <div class="myTopUp-page">
        <Header seet="4" />
        <el-row type="flex">
            <el-col :span="3" ></el-col>
            <el-col style="z-index: 3;">
                <div class="breadcrumb-box">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item>账号中心</el-breadcrumb-item>
                        <el-breadcrumb-item>我的预充值</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="content-box">
                    <LeftHurdle style="margin-right: 20px" />
                    <div class="box-rights">
                        <div class="box-right-top">我的预充值</div>
                        <div class="balance-box">
                            <div class="title">
                                <span>可用余额</span>
                                <span @click="addRecharge()">充值</span>
                            </div>
                            <div class="number">
                                <span>{{availableBalance}} 元</span>
                                <span @click="approvalRecord()">充值审批记录</span>
                            </div>
                        </div>
                        <div class="from-box">
                            <span class="from-box-text1">关联单号：</span>
                            <el-input v-model="form.correlationId" placeholder="请输入内容" size="mini" clearable style="width: 180px;margin:0 12px;"></el-input>
                            <span class="from-box-text1">变动类型：</span>
                            <el-select v-model="form.businessType" clearable placeholder="请选择" size="mini" style="width: 120px;margin:0 12px;" @change="getList">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                            <span class="from-box-text1">余额变动时间：</span>
                            <el-date-picker
                                v-model="timeSearch"
                                type="daterange"
                                size="mini"
                                align="right"
                                unlink-panels
                                clearable
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                 style="width: 250px;"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                :default-time="['00:00:00', '23:59:59']"
                                :picker-options="pickerOptions"
                            >
                            </el-date-picker>
                        </div>
                        <div class="from-box">
                            <div class="button">
                                <div class="search-btn" @click="getList()">查询</div>
                                <div class="export-btn"
                                    :class="{
                                        notAllowed: disabledExport
                                    }" 
                                    @click="onExport()"
                                >
                                    <i v-if="disabledExport" class="el-icon-loading"></i>
                                    导出
                                </div>
                            </div>
                            <div class="from-box-text">注：默认查询三个月内余额明细</div>
                        </div>
                        <div class="table-box">
                            <el-table ref="topUpTable"
                                :data="tableData" style="width: 100%" border
                                :header-cell-style="{'background-color': '#ccc','color': '#000000d9'}"
                            >
                                <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
                                <!-- <el-table-column prop="id" label="编号" align="center" minWidth="180"></el-table-column> -->
                                <el-table-column  label="账号" align="center" width="120">
                                    <template slot-scope="scope">
                                        <span v-if='scope.row.userAccount == null'>---</span>
                                        <span v-else>{{scope.row.userAccount}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="userName" label="操作人" align="center" width="110"></el-table-column>
                                <el-table-column prop="correlationId" label="订单号" align="center" min-width="200">
                                    <template slot="header">
                                        <span style="margin-right: 10px;">关联单号</span>
                                        <el-tooltip class="item" effect="dark" content="包含订单号、账单号、预测值单号、扣余额流水号、售后单号" placement="bottom-start">
                                            <i class="el-icon-question"></i>
                                        </el-tooltip>                       
                                    </template>
                                </el-table-column>
                                <el-table-column prop="income" label="收入" align="center" width="90">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.income}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="spend" label="支出" align="center" width="90">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.spend}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="completeTime" label="余额变动时间" align="center" width="160"></el-table-column>
                                <el-table-column prop="businessType" label="变更类型" align="center" width="110">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.businessType == 1">消费</span>
                                        <span v-if="scope.row.businessType == 2">充值</span>
                                        <span v-if="scope.row.businessType == 3">退款</span>
                                        <span v-if="scope.row.businessType == 4">抵扣</span>
                                        <span v-if="scope.row.businessType == 5">退余额</span>
                                        <span v-if="scope.row.businessType == 6">凭证余额充值</span>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column prop="state" label="状态" align="center" width="80">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.state == 1">待审核</span>
                                        <span v-if="scope.row.state == 2">完成</span>
                                        <span v-if="scope.row.state == 3">审核驳回</span>
                                        <span v-if="scope.row.state == 4">订单取消</span>
                                        <span v-if="scope.row.state == 5">审核中</span>
                                    </template>
                                </el-table-column> -->
                                <el-table-column prop="remark" label="补充备注" align="center" min-width="180">
                                </el-table-column>
                                <!-- <el-table-column
                                    fixed="right"
                                    label="操作"
                                    align="center"
                                    width="120">
                                    <template slot-scope="scope">
                                        <el-button @click="detailsBtn(scope.row)" style="color: #cd9f49;" type="text" size="small">{{ scope.row.businessType == 2 && scope.row.state == 1 ? '编辑' : '查看' }}</el-button>
                                        <el-button v-if="scope.row.state == 3" @click="againSubmit(scope.row)" style="color: #cd9f49;" type="text" size="small">重新提交</el-button>
                                    </template>
                                </el-table-column> -->
                            </el-table>
                            <div class="tablePage">
                                <el-pagination
                                    background
                                    @current-change="handleCurrentChange"
                                    :current-page="currentPage"
                                    :page-sizes="[10, 20, 50, 100]"
                                    :page-size="pageSize"
                                    layout="total, prev, pager, next, jumper"
                                    :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                    <MyTopUpDialog
                        ref="MyTopUpDialog"
                        :fileList="topUpFileList"
                        :dialog.sync="dialogVisibleTopUp"
                        :delBtn="topUpFileDelBtn"
                        :dialogTitle="dialogTitle"
                        @del="topUpFileDel"
                        @before="beforeTopUpFile"
                        @request="requestTopUpFile"
                        @determine="onDetermine"
                        @onCloseDialog="onCloseDialog"
                    ></MyTopUpDialog>
                    <ApprovalRecordDialog
                        v-if="ApprovalRecordDialog"
                        :dialog.sync="ApprovalRecordDialog"
                        @detailsBtn="detailsBtn"
                    ></ApprovalRecordDialog>
                </div>
            </el-col>
            <el-col :span="3" ></el-col>
        </el-row>
        <Footer />
    </div>
</template>

<script>
import Header from "@/components/Layout/header.vue";
import Footer from "@/components/Layout/footer.vue";
import LeftHurdle from "@/components/Layout/leftHurdle.vue";
import MyTopUpDialog from './components/myTopUpDialog.vue'
import ApprovalRecordDialog from './components/myTopUpApprovalRecordDialog.vue'
import {
    uploadApproveFile,
    balanceQuery,
    preChargeSubSave,
    queryLog,
    preChargeSubQuery,
    exportPreChargeList,
    PreChargeSubChange
} from "@/api/order/order"
export default {
    components: { 
        Header,
        Footer,
        LeftHurdle,
        MyTopUpDialog,
        ApprovalRecordDialog
    },
    data() {
        return {
            timeSearch: [],
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                        picker.$emit('pick', [start, end])
                    }
                }]
            },
            options: [
                {
                    value: undefined,
                    label: '全部'
                },
                {
                    value: 1,
                    label: '消费'
                },
                {
                    value: 2,
                    label: '充值'
                },
                {
                    value: 3,
                    label: '退款'
                },
                {
                    value: 4,
                    label: '抵扣'
                },
                {
                    value: 5,
                    label: '退余额'
                },
                // {
                //     value: 6,
                //     label: '凭证余额充值'
                // }
            ],
            tableData: [],
            form: {},
            currentPage: 1,
            pageSize: 20,
            total: 0,
            topUpFileList: [],
            topUpFileDelBtn: true,
            dialogVisibleTopUp: false,
            ApprovalRecordDialog: false,
            dialogTitle: '',
            availableBalance: 0,
            disabledExport: false
        }
    },
    mounted() {
        if (this.$route.params.searchId) {
            this.form.correlationId = this.$route.params.searchId
        }
        this.getList()
        balanceQuery().then(res => {
            if (res.code == '00000') {
                this.availableBalance = res.data.balance
            } else {
                this.$message({message:"接口调用失败", type: 'error'});
            }
        })
    },
    methods: {
        // 充值审批记录
        approvalRecord() {
            this.ApprovalRecordDialog = true
        },
        // 导出
        onExport() {
            if (this.disabledExport) return
            let data = {
                ...this.form
            }
            if (this.timeSearch != null && this.timeSearch.length > 0) {
                data.startTime = this.timeSearch[0]
                data.endTime = this.timeSearch[1]
            }
            this.disabledExport = true
            exportPreChargeList(data).then(() => {
                this.disabledExport = false
            })
        },
        // 充值
        addRecharge() {
            this.dialogTitle = '新增预充值认款单'
            this.topUpFileDelBtn = true
            this.dialogVisibleTopUp = true
        },
        // 提交充值
        onDetermine(data, title) {
            if (title == '新增预充值认款单' || title == '重新提交预充值认款单') {
                preChargeSubSave({...data}).then(res => {
                    if (res.code == '00000') {
                        this.onCloseDialog()
                        this.getList()
                        this.$message({
                            type: "success",
                            message: "添加成功!",
                        });
                    } else {
                        this.$refs.MyTopUpDialog.isLoading()
                        this.$message({message: res.desc, type: 'error'});
                    }
                })
            } else {
                PreChargeSubChange({...data, state: 1}).then(res => {
                    if (res.code == '00000') {
                        this.onCloseDialog()
                        this.getList()
                        this.$message({
                            type: "success",
                            message: "编辑成功!",
                        });
                    } else {
                        this.$message({message:"接口调用失败", type: 'error'})
                    }
                })
            }
        },
        // 查看
        detailsBtn(row) {
            if (row.businessType == 1) {
                let obj = JSON.stringify({
                    orderNo: row.orderNo,
                    invoiceId: row.invoiceId,
                    id: row.orderId
                })
                window.open(`/workbench/orderDetails?item=${obj}`,' _blank')
            } else if (row.businessType == 3) {
                window.open(`/workbench/returnGoods?id=${row.afterSaleId}`, ' _blank')
            } else {
                // preChargeSubQuery(row.orderNo).then(res => {
                preChargeSubQuery(row.id).then(res => {
                    if (res.code == '00000') {
                        if (row.businessType == 2 && row.state == 1) {
                            this.dialogVisibleTopUp = true
                            this.topUpFileDelBtn = true
                            this.dialogTitle = '编辑预充值认款单'
                        } else {
                            this.dialogVisibleTopUp = true
                            this.topUpFileDelBtn = false
                            this.dialogTitle = '查看预充值认款单'
                        }
                        let obj = {
                            bankAccount: res.data.bankAccount,
                            bankName: res.data.bankName,
                            remittanceAmount: res.data.remittanceAmount,
                            remittanceCompany: res.data.remittanceCompany,
                            id: row.orderNo
                        }
                        this.topUpFileList = res.data.uploadHistoryRecordList
                        this.$refs.MyTopUpDialog.onHeadForm(obj)
                    } else {
                        this.$message({message: res.desc, type: 'error'})
                    }
                })
            }
        },
        // 重新提交
        againSubmit(row) {
            preChargeSubQuery(row.orderNo).then(res => {
                if (res.code == '00000') {
                    this.dialogVisibleTopUp = true
                    this.topUpFileDelBtn = true
                    this.dialogTitle = '重新提交预充值认款单'
                    let obj = {
                        bankAccount: res.data.bankAccount,
                        bankName: res.data.bankName,
                        remittanceAmount: res.data.remittanceAmount,
                        remittanceCompany: res.data.remittanceCompany
                    }
                    this.topUpFileList = res.data.uploadHistoryRecordList
                    this.$refs.MyTopUpDialog.onHeadForm(obj)
                } else {
                    this.$message({message: res.desc, type: 'error'})
                }
            })
        },
        // 关闭充值窗口
        onCloseDialog() {
            this.dialogVisibleTopUp = false
            this.topUpFileList = []
        },
        getList() {
            let data = {
                pageNum: this.currentPage,
                pageSize: this.pageSize,
                ...this.form
            }
            if (this.timeSearch != null && this.timeSearch.length > 0) {
                data.startTime = this.timeSearch[0]
                data.endTime = this.timeSearch[1]
            }
            queryLog(data).then(res => {
                if (res.code == '00000') {
                    this.tableData = res.data.records
                    this.total = res.data.total
                } else {
                    this.$message({message: res.desc, type: 'error'})
                }
            })
        },
        // 当前页
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
        // 审批文件删除
        topUpFileDel(item, index) {
            console.log(item, index);
            const loading = this.$loading({
                lock: true,
                text: '正在删除中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.topUpFileList.splice(index,1)
            loading.close()
            // delApproveFile(item.id).then(res => {
            //     if(res.code == '00000'){
            //         this.$message({message:"删除成功", type: 'success'});
            //     } else {
            //         this.$message({message:"删除失败", type: 'error'});
            //     }
            // })
        },
        // 汇款凭证文件上传
        // 上传文件前
        beforeTopUpFile(file) {
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1)
 
            const whiteList = ["jpg","jpeg", "png", "pdf", "JPG","JPEG", "PNG", "PDF"]
            const size = 1024 * 1024 * 10

            if (this.topUpFileList.length >= 10) {
                this.$message({message:"最多上传10个文件", type: 'error'})
                return false
            }
            // let aName = this.topUpFileList.find(item => item.fileName == file.name)
            // if(aName){
            //     this.$message({message:"重复上传文件名！", type: 'warning'});
            //     return false;
            // }
            if (whiteList.indexOf(fileSuffix) === -1) {
                this.$message({message:"上传文件只能是 jpg,png,pdf格式", type: 'warning'})
                return false
            } else if (size <= file.size) {
                this.$message({message:"上传文件的大小需小于等于10MB", type: 'warning'})
                return false;
            }
        },
        // 自定义上传
        requestTopUpFile(params) {
            // console.log(this.topUpFileList,'up');
            // console.log(params,'up2');
            // 获取文件对象
            let file = params.file
            if(this.topUpFileList.length >= 10){
                this.$message({message:"最多上传10个文件", type: 'error'});
                return false;
            }
            var typeArr = ['image/jpeg', 'image/png', 'image/JPG', 'application/pdf' ]
            if (typeArr.indexOf(file.type) == -1) {
                this.$message.error('上传文件只能是 JPG/PNG/JPEG/PDF 格式!')
                return
            }
            // 判断文件大小
            if (file.size / 1024 / 1024 > 10) {
                this.$message.error('上传文件大小不能超过 10MB!')
                return
            }
            let formData = new FormData()
            formData.append("file", params.file)
            formData.append("type", 5)
            uploadApproveFile(formData).then(res => {
                if (res.code == '00000') {
                    this.$message({message:"上传成功", type: 'success'})
                    this.topUpFileList.push(res.data)
                } else {
                    this.$message({message:"上传失败", type: 'error'})
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.myTopUp-page {
    .breadcrumb-box {
        margin-top: 30px;
        padding-bottom: 20px;   
        
        .el-breadcrumb {
            font-size: 18px;
        }
        
    }

    .content-box {
        display: flex;

        .box-rights {
            width: 1150px !important;
            border: 1px solid #d7d7d7;
            border-radius: 10px;

            .box-right-top {
                height: 40px;
                line-height: 40px;
                padding-left: 40px;
                background: #d7d7d7;
                border-bottom: 1px solid #d7d7d7;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                font-weight: bold;
            }

            .balance-box {
                width: 270px;
                height: 130px;
                border: 1px solid #d7d7d7;
                border-radius: 10px;
                margin: 20px;

                .title {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 10px;
                    margin: 10px 0;

                    span {
                        font-size: 14px;
                        color: #333;

                        &:nth-child(2){
                            color: #cd9f49;
                            font-weight: bold;
                            cursor: pointer;

                        }
                    }
                }
                .number {
                    font-size: 16px;
                    color: #aaa;
                    padding: 0 10px;
                    display: flex;
                    justify-content: space-between;
                    span {
                        color: #333;
                        &:nth-child(2){
                            font-size: 14px;
                            color: rgb(140, 196, 211);
                            font-weight: bold;
                            cursor: pointer;

                        }
                    }
                }
            }

            .from-box {
                display: flex;
                padding: 0 20px;
                margin-bottom: 10px;

                .button {
                    display: flex;
                    align-items: center;

                    div {
                        border-radius: 8px;
                        border: 1px solid #000;
                        text-align: center;
                        width: 80px;
                        height: 25px;
                        line-height: 25px;
                        cursor: pointer;
                        margin-left: 10px;
                    }
                    .search-btn {
                        border-color: #cd9f49;
                        background-color: #cd9f49;
                        color: #fff;
                    }
                    .export-btn {
                        background-color: #fff;
                        color: #000;
                    }
                }
                .from-box-text {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex-direction: column;
                    font-size: 13px;
                    margin-left: 10px;
                    color: rgb(105, 102, 102);
                }
                .from-box-text1 {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex-direction: column;
                    font-size: 15px;
                    color: rgb(105, 102, 102);
                }
            }

            .table-box {
                padding: 5px 0 20px;

                .tablePage {
                    display: flex;
                    justify-content: flex-end;
                    padding: 25px;
                    
                }
            }

        }
    }
}
// 分页样式
::v-deep .el-pagination.is-background .el-pager {
    .active {
        background-color: #cd9f49 !important;
    }
    li:hover {
        color: #cd9f49 !important;
    }
}
</style>