<template>
  <div>
    <!-- 查看审批文件 -->
    <el-dialog
      :visible="dialog"
      width="1000px"
      :title="'充值审批记录'"
      center
      append-to-body
      top="5vh"
      @close="closeDialog"
    >
      <div class="dialogVisibleBox" v-loading="loading">
        <div class="form-box">
          <div class="from-box">
              <span class="from-box-text1">审核状态:</span>
              <el-select v-model="auditState" clearable placeholder="请选择" size="mini" style="width: 120px;margin: 0 15px;" @change="getList">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-date-picker
                  v-model="timeSearch"
                  type="daterange"
                  align="right"
                  unlink-panels
                  clearable
                  size="mini"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  @change="getList"
              >
              </el-date-picker>
              <div class="from-box-text">注：默认查询三个月内余额明细</div>
          </div>
          <el-table ref="topUpTable"
              :data="tableData" style="width: 100%" border
              :header-cell-style="{'background-color': '#ccc','color': '#000000d9'}"
          >
              <el-table-column prop="remittanceCompany" label="汇款公司" align="center" minWidth="160"></el-table-column>
              <el-table-column prop="bankName" label="开户银行" align="center" width="120"></el-table-column>
              <el-table-column prop="bankAccount" label="银行账号" align="center" width="180"></el-table-column>
              <el-table-column prop="remittanceAmount" label="充值金额" align="center" min-width="80"></el-table-column>
              <el-table-column prop="createName" label="操作人" align="center" min-width="100"></el-table-column>
              <el-table-column prop="createTime" label="操作时间" align="center" width="160"></el-table-column>
              <el-table-column prop="state" label="审核状态" align="center" width="80">
                  <template slot-scope="scope">
                      <span v-if="scope.row.state == 1">待审核</span>
                      <span v-if="scope.row.state == 2">审核通过</span>
                      <span v-if="scope.row.state == 3">审核驳回</span>
                  </template>
              </el-table-column>
              <el-table-column prop="secondAuditTime" label="审核时间" align="center" width="160"></el-table-column>
              <el-table-column prop="remark" label="补充备注" align="center" width="150"></el-table-column>
              <el-table-column
                  fixed="right"
                  label="操作"
                  align="center"
                  width="60">
                  <template slot-scope="scope">
                      <el-button @click="detailsBtn(scope.row)" style="color: #cd9f49;" type="text" size="small">查看</el-button>
                  </template>
              </el-table-column>
          </el-table>
          <div class="tablePage">
              <el-pagination
                  background
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total="total">
              </el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
    preChargeSubqueryList
} from "@/api/order/order"
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      timeSearch: [],
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      options: [
        {
          value: undefined,
          label: '全部'
        },
        {
          value: 1,
          label: '待审核'
        },
        {
          value: 2,
          label: '审核通过'
        },
        {
          value: 3,
          label: '审核驳回'
        }
      ],
      auditState: undefined
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        auditState: this.auditState
      }
      if (this.timeSearch != null && this.timeSearch.length > 0) {
        data.createStartTime = this.timeSearch[0]
        data.createEndTime = this.timeSearch[1]
      }
      preChargeSubqueryList(data).then(res => {
        if (res.code == '00000') {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.$message({message:"接口调用失败", type: 'error'});
        }
      })
    },
    // 当前页
    handleCurrentChange(val) {
        this.currentPage = val
        this.getList()
    },
    detailsBtn(data) {
      this.$emit('detailsBtn', data)
    },
    // 关闭窗口
    closeDialog() {
      this.loading = false
      this.$emit('update:dialog', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.from-box {
  display: flex;
  padding: 0 20px;
  margin-bottom: 10px;
  .button {
      display: flex;
      align-items: center;

      div {
          border-radius: 8px;
          border: 1px solid #000;
          text-align: center;
          width: 80px;
          height: 26px;
          line-height: 30px;
          cursor: pointer;
          margin-left: 10px;
      }
      .search-btn {
          border-color: #cd9f49;
          background-color: #cd9f49;
          color: #fff;
      }
      .export-btn {
          background-color: #fff;
          color: #000;
      }
  }
  .from-box-text {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      font-size: 13px;
      margin-left: 10px;
      color: rgb(105, 102, 102);
  }
  .from-box-text1 {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      font-size: 15px;
      color: rgb(105, 102, 102);
  }
}
</style>