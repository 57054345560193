<template>
    <div>
        <!-- 查看审批文件 -->
        <el-dialog
            :visible="dialog"
            width="580px"
            :title="dialogTitle"
            center
            append-to-body
            @close="closeDialog"
        >
            <div v-if="delBtn" class="dialogVisibleBox" v-loading="loading">
                <div class="title">1、填写汇款信息</div>
                <div class="form-box">
                    <el-form ref="headForm" :model="headForm" label-width="100px" :rules="rules" :inline="true" size="small">
                        <el-form-item label="汇款公司:" prop="remittanceCompany">
                            <el-input v-model="headForm.remittanceCompany" :disabled="!delBtn" placeholder="请输入汇款公司"></el-input>
                        </el-form-item>
                        <el-form-item label="开户银行:" prop="bankName">
                            <el-input v-model="headForm.bankName" :disabled="!delBtn" placeholder="请输入开户银行"></el-input>
                        </el-form-item>
                        <el-form-item label="银行账号:" prop="bankAccount">
                            <el-input v-model="headForm.bankAccount" :disabled="!delBtn" placeholder="请输入银行账号"></el-input>
                        </el-form-item>
                        <el-form-item label="汇款金额:" prop="remittanceAmount">
                            <el-input @input="handleInputPrice($event)" v-model="headForm.remittanceAmount" :disabled="!delBtn" placeholder="请输入汇款金额"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="title-box">
                    <div class="title">2、上传汇款凭证</div>
                    <div class="btn" v-if="delBtn">
                        <el-upload
                            v-if="fileList.length < 10"
                            class="approveUpload-file"
                            ref="approveUpload"
                            action=""
                            accept=".jpg, .jpeg, .png, .pdf"
                            :multiple="true"
                            :auto-upload="true"
                            :show-file-list="false"
                            :http-request="requestFile"
                        >
                            <el-button size="small" type="warning">上传文件</el-button>
                        </el-upload>
                        <el-tooltip v-else class="item" effect="dark" content="最多上传10个文件!" placement="top">
                            <el-button size="small" type="warning">上传文件</el-button>
                        </el-tooltip>
                    </div>
                </div>
                <div class="hint-box">
                    <div>注：支持的图片格式为jpg，jpeg，png，pdf。务必上传清晰图片，单个图片需≤10M</div>
                </div>
                <div class="fileListBox" v-if="fileList.length > 0">
                    <div class="fileList flex-between" v-for="(item,index) in fileList" :key="index">
                        <div style="width: 68%;">审批文件{{index+1}}：{{item.fileName}}</div>
                        <div class="fileBtn" style="text-align: right;">
                            <span @click="filePreview(item)">在线预览</span>
                            <el-popconfirm
                                v-if="delBtn"
                                title="确定删除吗？"
                                @confirm="fileDel(item,index)"
                            >
                                <span slot="reference">删除</span>
                            </el-popconfirm>
                        </div>
                    </div>
                </div>
                <div class="fileListBox" v-else>
                    <el-empty description="无文件" :image-size="90"></el-empty>
                </div>
            </div>
            <div v-else class="dialogVisibleBox" v-loading="loading">
                <el-form ref="headForm" :model="headForm" label-width="130px" :rules="rules" :inline="true" size="small">
                    <el-form-item label="汇款公司:">
                        <div style="width: 350px">{{headForm.remittanceCompany}}</div>
                    </el-form-item>
                    <el-form-item label="开户银行:">
                        <div style="width: 350px">{{headForm.bankName}}</div>
                    </el-form-item>
                    <el-form-item label="银行账号:">
                        <div style="width: 350px">{{headForm.bankAccount}}</div>
                    </el-form-item>
                    <el-form-item label="汇款金额:">
                        <div style="width: 350px">{{headForm.remittanceAmount}}</div>
                    </el-form-item>
                    <el-form-item label="汇款凭证:">
                        <div class="fileListBox" style="width: 350px;margin-top: 0;height: auto">
                            <div class="fileList flex-between" v-for="(item,index) in fileList" :key="index">
                                <div style="width: 68%;">{{item.fileName}}</div>
                                <div class="fileBtn" style="text-align: right;width: 160px;">
                                    <span @click="filePreview(item)">预览</span>
                                    <span @click="onDownload(item)">下载</span>
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button v-if="delBtn" :disabled="loading" size="small" type="warning" @click="submitBtn()">提 交</el-button>
                <el-button size="small" @click="closeDialog()">取 消</el-button>
            </div>
            
        </el-dialog>
        <!-- 审批文件、汇款凭证 预览 jpg/png/pdf -->
        <el-image-viewer
            v-if="showViewer"
            :on-close="closeShowViewer"
            :url-list="showViewerList"
            style="z-index: 3000"
        />
        <!-- <previewPDF
            v-if="showPDF"
            ref="previewPDF"
            :url="srcPDF"
            :title="titlePDF"
            @closeShowPDF="closeShowPDF"
        ></previewPDF> -->
    </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
// import previewPDF from '@/components/public/previewPDF.vue'
export default {
    components: { ElImageViewer },
    props: {
        fileList: {
            type: Array,
        },
        dialog: {
            type: Boolean,
            default: false
        },
        delBtn: {
            type: Boolean,
            default: false
        },
        dialogTitle: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            loading: false,
            showViewer: false,
            showViewerList: [],// 图片预览
            // showPDF: false,
            // srcPDF: '',// pdf预览
            // titlePDF: '',
            headForm: {
                bankAccount: '',
                bankName: '',
                remittanceAmount: '',
                remittanceCompany: ''
            },
            rules: {
                remittanceCompany: [
                    { required: true, message: '汇款公司不能为空！', trigger: 'blur' },
                ],
                bankAccount: [
                    { required: true, message: '开户银行不能为空！', trigger: 'blur' }
                ],
                bankName: [
                    { required: true, message: '银行账号不能为空！', trigger: 'blur' }
                ],
                remittanceAmount: [
                    { required: true, message: '汇款金额不能为空！', trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        // 下载
        exportFile(data, fileName) {
            console.log(123, data, fileName)
            const callback = (data) => {
                // 创建a标签，使用 html5 download 属性下载，
                const link = document.createElement('a')
                // 创建url对象
                const objectUrl = window.URL.createObjectURL(new Blob([data]))
                link.style.display = 'none'
                link.href = objectUrl
                // 自定义文件名称， fileName
                link.download = fileName
                document.body.appendChild(link)
                link.click()
                // 适当释放url
                window.URL.revokeObjectURL(objectUrl)
            }
            // 把接口返回的url地址转换为 blob
            const xhr = new XMLHttpRequest()
            xhr.open('get', data, true)
            xhr.responseType = 'blob'
            xhr.onload = () => {
                // 返回文件流，进行下载处理
                callback(xhr.response)
            }
            xhr.send()
        },
        onDownload(data) {
            try {
                this.exportFile(data.fileUrl, data.fileName) // 调用方式
            } catch (err) {
                // 兼容模式下，IE
                // const exportBlob = new Blob([data])
                if (navigator.userAgent.indexOf('Trident') > -1) {
                window.navigator.msSaveBlob(data.fileUrl, data.fileName)
                } else {
                this.exportFile(data.fileUrl, data.fileName) // 调用方式
                }
            }
        },
        handleInputPrice(e) {
            let num = "" + e
            num = num.replace(/[^\d.]/g, "") // 清除“数字”和“.”以外的字符
                    .replace(/\.{2,}/g, ".") // 只保留第一个. 清除多余的
                    .replace(/^\./g, "") // 保证第一个为数字而不是.
                    .replace(".", "$#$")
                    .replace(/\./g, "")
                    .replace("$#$", ".")
            if (num.indexOf(".") < 0 && num !== "") {
                num = parseFloat(num) + ""
            } else if (num.indexOf(".") >= 0) {
                num = num.replace(/^()*(\d+)\.(\d\d).*$/, "$1$2.$3") // 只能输入两个小数
            }
            this.headForm.remittanceAmount = num
        },
        onHeadForm(data) {
            this.headForm = data
        },
        // 审批文件在线预览
        filePreview(item) {
            // console.log(item);
            let fileName = item.fileUrl.substring(item.fileUrl.lastIndexOf(".") + 1)
            console.log(fileName,item.fileUrl)
            if(fileName == 'pdf' || fileName == 'PDF'){
                // this.titlePDF = item.fileName
                // let i = item.fileUrl.indexOf('/order')
                // this.srcPDF = '/pdf'+item.fileUrl.slice(i)
                // this.srcPDF = item.fileUrl
                // this.srcPDF = '/pdf/order%2Fimages%2Fb5cff203883c47c1922a94b23b9468e4.pdf'
                // this.srcPDF = 'http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf'
                // this.showPDF = true
                window.open(item.fileUrl)
            } else {
                this.showViewer = true
                this.showViewerList = [item.fileUrl]
            }
            
        },
        closeShowViewer() {
            this.showViewer = false
        },
        closeShowPDF() {
            this.showPDF = false
        },
        // 审批文件删除
        fileDel(item,index) {
            this.$emit('del', item,index)
        },
        // // 上传前
        // beforeFile(file){
        //     console.log(1)
        //     this.$emit('before',file)
        // },
        // 自定义上传
        requestFile(params) {
            this.$emit('request', params)
        },
        // 关闭窗口
        closeDialog() {
            this.loading = false
            this.$emit('update:dialog', false)
            this.$emit('onCloseDialog')
            this.$refs['headForm'].clearValidate()
            this.headForm = {
                bankAccount: '',
                bankName: '',
                remittanceAmount: '',
                remittanceCompany: ''
            }
        },
        isLoading() {
            this.loading = false
        },
        // 提交
        submitBtn(){
            this.$refs['headForm'].validate((valid) => {
                if(valid){
                    if (this.fileList.length == 0) {
                        return this.$message({message:"请上传汇款凭证", type: 'error'})
                    }
                    this.$confirm('确认提交？','提示', {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then( () => {
                        this.loading = true
                        let data = {
                            ...this.headForm,
                            uploadHistoryRecordList: this.fileList
                        }
                        this.$emit('determine', data, this.dialogTitle)
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.dialogVisibleBox {
    width: 100%;
    // height: 380px;
    position: relative;

    .fileListBox {
        width: 100%;
        height: 190px;
        margin-bottom: 15px;
        margin-top: 15px;
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width : 7px;
            height: 1px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 7px;
            box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
            background   : #808080;
        }
        &::-webkit-scrollbar-track {
            box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 7px;
            background   : #ededed;
        }

        .el-empty {
            padding: 25px 0;
        }

        .fileList {
            margin-bottom: 15px;

            .fileBtn {
                width: 32%;
                color: #CD9F49;

                span {
                    cursor: pointer;
                    margin: 0 10px;
                }
            }
        }
    }
    .form-box {
        margin: 20px 0 0;
    }
    .title {
        font-size: 18px;
        font-weight: bold;
    }
    .title-box {
        display: flex;
        align-items: center;
        margin: 10px 0;

        .btn {
            margin-left: 12px;
        }
    }
    .hint-box {
        width: 100%;
        font-size: 14px;
        color: #666;
    }
}
.approveUpload-file {
    display: inline-block;
    margin-right: 30px;
}

</style>